.loadingEllipsis-one {
  opacity: 0;
  animation: leDot 1s infinite;
  animation-delay: 0s;
}

.loadingEllipsis-two {
  opacity: 0;
  animation: leDot 1s infinite;
  animation-delay: 0.2s;
}

.loadingEllipsis-three {
  opacity: 0;
  animation: leDot 1s infinite;
  animation-delay: 0.3s;
}

@keyframes leDot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
